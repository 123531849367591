import React, { useMemo, useState } from "react";
import {
  Tooltip,
  Box,
  IconButton,
  Grow,
  CircularProgress,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import CasinoIcon from "@mui/icons-material/Casino";

const importAll = (r: any) => {
  let images: string[] = [];
  r.keys().map((item: any) => {
    images.push(r(item));
  });
  return images;
};

function shuffleArray<T>(array: T[]): T[] {
  const result = [...array];
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
}
function ImageViewer({ width, height }: { width: string; height: string }) {
  const images = useMemo(
    () =>
      importAll(
        (require as any).context("../images", false, /\.(png|jpe?g|svg)$/i)
      ).filter((image: any) => !image.includes("IMG_3398")),
    []
  );
  const [isLoading, setIsLoading] = useState(true);

  const [shuffledImages, setShuffledImages] = useState(
    shuffleArray([...images])
  );
  const [imageIndex, setImageIndex] = useState(-1);

  const defaultImage = require("../images/IMG_3398.jpg");

  const justOneImage = true;
  const cover = require("../images/cover.jpg");

  const changeImage = () => {
    setIsLoading(true);
    setImageIndex((prevIndex) => {
      let newIndex = prevIndex + 1;
      if (newIndex >= shuffledImages.length) {
        setShuffledImages(shuffleArray(images));
        newIndex = -1;
      }

      return newIndex;
    });
  };
  return (
    <Box
      width={width}
      height={height}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      p={1}
    >
      <Box />
      {isLoading && <CircularProgress />}
      <img
        src={
          justOneImage
            ? cover
            : imageIndex !== -1
            ? shuffledImages[imageIndex]
            : defaultImage
        }
        alt="random"
        style={{
          objectFit: "contain",
          maxWidth: "90%",
          maxHeight: "90%",
          borderRadius: "4%",
          display: isLoading ? "none" : "block",
        }}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
      {justOneImage ? (
        <Box />
      ) : (
        <IconButton onClick={changeImage}>
          <CasinoIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default ImageViewer;
