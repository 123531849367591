import React, { useMemo } from "react";
import { Box, Typography, IconButton, Link } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import { Info } from "../info";

const parseDescription = (description: string) => {
  const linkRegEx = /\[([^\]]+),\s*([^\]]+)\]/g;
  let match;
  let lastIndex = 0;
  let result: (string | JSX.Element)[] = [];

  while ((match = linkRegEx.exec(description)) !== null) {
    if (match.index > lastIndex) {
      result.push(description.slice(lastIndex, match.index));
    }

    result.push(
      <Link
        href={match[2]}
        color="primary"
        underline="none"
        target="_blank"
        rel="noopener noreferrer"
        key={match.index}
      >
        {match[1]}
      </Link>
    );

    lastIndex = match.index + match[0].length;
  }

  if (lastIndex < description.length) {
    result.push(description.slice(lastIndex));
  }

  return result;
};

function Profile({ width }: { width: string }) {
  const description = useMemo(() => parseDescription(Info.Description), []);
  return (
    <Box
      width={width}
      // height={height}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p={1}
    >
      <Typography variant="h2">{Info.Header}</Typography>
      <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
        {description}
      </Typography>

      <Box>
        <IconButton
          color="primary"
          href={Info.Linkedin}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn profile"
        >
          <LinkedInIcon fontSize="large" />
        </IconButton>

        <IconButton color="primary" href={Info.Email} aria-label="Email">
          <MailIcon fontSize="large" />
        </IconButton>

        <IconButton
          color="primary"
          href={Info.Resume}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Resume"
        >
          <InsertDriveFile fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Profile;
