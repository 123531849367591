export const Info = {
  Header: "Hi, I'm Victor! 👋",
  Description: `
  I'm a software engineer at [Scale AI, https://scale.com], currently living in San Francisco.
  
  I recently graduated from UC Berkeley with a B.A. in Computer Science, and my past experience includes software engineering internships at [Amazon Web Services, https://aws.amazon.com/], [ServiceNow, https://www.servicenow.com/], and [Expedock, https://www.expedock.com/]. On campus, I developed websites, planned fun club events, and built community as a part of [Web Development at Berkeley, https://webatberkeley.org].
  
  In my spare time, I enjoy lifting, watching movies/TV, shopping, and journaling (~600 days straight and counting!). As of late, I've been learning the piano, furnishing my new apartment, and enjoying walks around SF!

  `,
  Linkedin: "https://www.linkedin.com/in/vmshen/",
  Email: "mailto:vshen@berkeley.edu",
  Resume: "/resume.pdf",
  Footer: "Made with ❤️ by Victor Shen",
};
