import React, { useState, useEffect } from "react";
import Profile from "./Profile";
import {
  ThemeProvider,
  CssBaseline,
  Switch,
  Box,
  Typography,
  Fade,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import ImageViewer from "./ImageViewer";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Info } from "../info";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const theme = isDarkMode ? darkTheme : lightTheme;

  const handleThemeChange = () => {
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    setIsDarkMode(prefersDarkMode);
  }, []);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Fade in={true} timeout={1000}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          p={1}
          height="100vh"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Switch checked={isDarkMode} onChange={handleThemeChange} />
            {isDarkMode ? (
              <DarkModeIcon></DarkModeIcon>
            ) : (
              <LightModeIcon></LightModeIcon>
            )}
          </Box>

          <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            justifyContent="space-around"
            p={2}
          >
            <Profile width={isSmallScreen ? "100%" : "65%"} />
            <ImageViewer
              width={isSmallScreen ? "100%" : "35%"}
              height={isSmallScreen ? "60vh" : "80vh"}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            p={0.2}
          >
            <Typography variant="caption"> {Info.Footer} </Typography>
          </Box>
        </Box>
      </Fade>
    </ThemeProvider>
  );
}

export default App;
